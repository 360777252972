<template>
  <div class="tokyo_tm_portfolio">
    <div class="tokyo_tm_title">
      <div>
        <div class="left">
          <h3>Art Portfolio</h3>
          <p>Hand drawn and digital art created by me. Art merch is on REDBUBBLE</p>
        </div>
        <!-- End Left -->
        <div class="portfolio_filter">
          <hr />
          <ul>
            <li>
              <a
                v-on:click="activetab = 1"
                :class="[activetab === 1 ? 'active' : '']"
                >ALL</a
              >
            </li>
            <!-- End All for tabcontent 1 -->
            <li>
              <a
                v-on:click="activetab = 2"
                :class="[activetab === 2 ? 'active' : '']"
                >CREATURES</a
              >
            </li>
            <!-- End Creature tabcontent 2 -->
            <li>
              <a
                v-on:click="activetab = 3"
                :class="[activetab === 3 ? 'active' : '']"
                >ABSTRACT</a
              >
            </li>
            <!-- End Abstract tabcontent 3 -->
          </ul>
          <hr />
        </div>
        <!-- End .portfolio-filter -->
      </div>
    </div>
    <!-- End tokyo_tm_title -->

    <SocialShare :show-redbubble="true"
      :page-url="currentAbsoluteRoute"
    />

    <div v-if="activetab === 1" class="tabcontent">
      <ul class="portfolio_list">
        <CoolLightBox :items="allItems" :index="index" @close="index = null">
        </CoolLightBox>
        <li
          v-for="(image, imageIndex) in allItems"
          :key="imageIndex"
          @click="index = imageIndex"
        >
          <div class="inner">
            <div class="entry tokyo_tm_portfolio_animation_wrap">
              <img class="image parent-img-responsive" :src="image.thumb" alt="Portfolio" />
            </div>
          </div>
        </li>
        <!-- End li -->
      </ul>
      <!-- End .portfolio_list -->
    </div>
    <!-- End .tabcontent 1 -->

    <div v-if="activetab === 2" class="tabcontent">
      <ul class="portfolio_list">
        <CoolLightBox :items="creatureItems" :index="index" @close="index = null">
        </CoolLightBox>
        <li
          v-for="(image, imageIndex) in creatureItems"
          :key="imageIndex"
          @click="index = imageIndex"
        >
          <div class="inner">
            <div class="entry tokyo_tm_portfolio_animation_wrap">
              <img class="image parent-img-responsive" :src="image.thumb" alt="Portfolio" />
            </div>
          </div>
        </li>
        <!-- End li -->
      </ul>
      <!-- End .portfolio_list -->
    </div>
    <!-- End .tabcontent 2 -->

    <div v-if="activetab === 3" class="tabcontent">
      <ul class="portfolio_list">
        <CoolLightBox
          :items="abstractItems"
          :index="index"
          @close="index = null"
        >
        </CoolLightBox>
        <li
          v-for="(image, imageIndex) in abstractItems"
          :key="imageIndex"
          @click="index = imageIndex"
        >
          <div class="inner">
            <div class="entry tokyo_tm_portfolio_animation_wrap">
              <img class="image parent-img-responsive" :src="image.thumb" alt="Portfolio" />
            </div>
          </div>
        </li>
        <!-- End li -->
      </ul>
      <!-- End .portfolio_list -->
    </div>
    <!-- End .tabcontent 3 -->
  </div>
</template>

<script>
  let ROOT_PATH = 'https://www.toasterartist.com'
  import SocialShare from '../components/SocialShare'

  export default {
    metaInfo() {
      return {
        title: 'Toaster Artist | Art',
        meta: [
            // Twitter Card
            {name: 'twitter:card', content: 'summary'},
            {name: 'twitter:title', content: 'Toaster Artist | Art'},
            {name: 'twitter:description', content: 'Character and abstract art'},
            // image must be an absolute path
            {name: 'twitter:image', content: this.metaImage},
            // Facebook OpenGraph
            {property: 'og:title', content: 'Toaster Artist | Art'},
            {property: 'og:site_name', content: 'Toaster Artist'},
            {property: 'og:type', content: 'website'},
            {property: 'og:image', content:  this.metaImage},
            {property: 'og:description', content: 'Character and abstract art'},
        ]
      }
    },
    data() {
      return {
        metaImage: ROOT_PATH + require('../assets/img/logo/logo.png'),
        activetab: 1,
        index: null,
        currentAbsoluteRoute: "http://toasterartist.com" + this.$route.path,
      }
    },
    components: {
      SocialShare,
    },
    computed: {
      allItems() {
        let requiredContext = require.context('../assets/img/art/', true, /\.jpg$/)
        let images = this.importImages(requiredContext)

        return this.shuffleArray(images)
      },
      creatureItems() {
        let requiredContext = require.context('../assets/img/art/creatures/', false, /\.jpg$/)
        let images = this.importImages(requiredContext)

        return this.shuffleArray(images)
      },
      abstractItems() {
        let requiredContext = require.context('../assets/img/art/abstract/', false, /\.jpg$/)
        let images = this.importImages(requiredContext)

        return this.shuffleArray(images)
      },
    },
    methods: {
      importImages(requiredContext) {
        let images = []

        requiredContext.keys().map(key => {
          images.push({ src: requiredContext(key), thumb: requiredContext(key) })
        })

        return images
      },
      shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array
      }
    },
    watch: {
      $route(to,) {
        this.currentAbsoluteRoute = "http://toasterartist.com" + to.path
        this.metaImage = this.allItems[0].src
      }
    },
  };
</script>

<style lang="scss" scoped>
.left {
  padding-bottom: 10px;
}

.portfolio_filter {
  padding-top: 1px;
}
</style>
